import type { FC } from '../../lib/teact/teact';
import React, { useCallback,  useState} from '../../lib/teact/teact';
import {getActions} from "../../global";
import './kokish-styles.scss';


interface KokishProps {

}

const KokishArea: FC<KokishProps> = () => {
  const [chanelId, setChanelId] = useState('');
  const [files, setFiles] = useState<File[] >([]);

  const {
    openChat,

  } = getActions();


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      // @ts-ignore
      setFiles(e.target.files);
    }
  };

  const filesEvent = useCallback(() => new CustomEvent('filesUpload', {
    detail: {files}
  }), [files]);

  const handleUpload = async () => {
    window.dispatchEvent(filesEvent());
  };

  const openChanelId = () => {
    openChat({ id: chanelId })
  }


  return (
    <div className="kokish">
      <div className="kokish-block">
        <input value={chanelId} id={'KOKISH_chatId'} onChange={e => setChanelId(e.target.value)} type="text"/>
        <button id={'KOKISH_OpenChat'} onClick={openChanelId}>Open chat</button>
      </div>
      <div className="kokish-block">
        <input  multiple id={'KOKISH_OpenFilesDialog'} type="file" onChange={handleFileChange}/>
        <button onClick={handleUpload} id={'KOKISH_SendFilesDialog'}>send files</button>
      </div>

    </div>
  )
}
export default KokishArea;
